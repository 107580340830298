* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"); */

body {
  color: #fff;
  /* font-family: "Bebas Neue", sans-serif; */
  letter-spacing: 2px;
}

.mmt {
  margin-top: 3rem;
}
.mmt-5 {
  margin-top: 5rem;
}
.subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  word-spacing: 10px;
}
.gcolor {
  color: #ffad16;
}

.bs {
  box-shadow: 0px 0px 10px 5px #f2f2f2;
}

.tb {
  background-color: rgba(0, 0, 0, 0.452);
}

.btn-white {
  background-color: #fff;
  color: #000;
}

.btn-transaparent {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.btn-transaparent:hover {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 2rem;
  }
  .rh4 {
    font-size: 0.8rem !important;
  }
  .rp {
    font-size: 0.5rem;
  }
  .rtc {
    text-align: center !important;
  }
}
