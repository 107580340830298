.cus-nav {
  background-color: #000;
  color: #fff;
  padding: 0.4rem 0;
}

.link-style {
  color: #fff !important;
  margin-left: 1rem;
  font-weight: bold;
}

.lock {
  background-color: #52440e;
  padding: 1rem;
}

.nav-logo {
  height: 60px;
}

.text-logo {
  height: 60px;
}

.linkh {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.linkh:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.linkh:hover:after {
  transform: scaleX(1);
}
.linkh.fromRight:after {
  transform-origin: 100% 50%;
}
.linkh.fromLeft:after {
  transform-origin: 0% 50%;
}

.showcase {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/imgs/jungle.jpg");
}

.showcase1 {
  background: linear-gradient(to bottom, #0f1210 0%, #155c1d 100%);
  padding: 2rem;
  // padding: 1rem;
}

.commanbg {
  padding: 4rem 0;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/imgs/mainbg.jpg");
}

.home-title {
  font-size: 3rem;
  font-weight: bold;
}
.home-subtitle {
  font-size: 1.2rem;
  font-weight: 600;
}

.lbgcontainer {
  padding: 2rem;
  background: #000;
  border: 1.2rem solid #3b5244 !important;
}

.textgreen {
  color: #00dd01;
}

.minus {
  background: #d9d9d946;
}

.clockbox {
  border: 1.2rem solid #3b5244 !important;
}

.clockbox_box {
  border: 1.5rem solid #639474 !important;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showcasevideo {
  height: 500px;
  width: 100%;
}

.textnumber {
  font-size: 10rem;
  color: #00dd01;
}

.slo {
  height: 40px;
}

.ddark {
  background-color: #000 !important;
  color: #fff;
  border: none;
}

@media only screen and (max-width: 600px) {
  .textnumber {
    font-size: 2rem;
    padding: 1rem !important;
  }

  .showcase,
  .lbg,
  .teambg,
  .buybg {
    height: auto;
  }

  .showcase {
    padding: 4rem 0;
  }

  .showcasevideo {
    height: auto;
  }

  .link-style {
    text-align: center;
  }
}
